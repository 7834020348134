import React, { useState, useEffect, useContext } from "react";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { UserContext } from "../context";
import moment from "moment";
import {useNavigate} from "react-router-dom";


const Subscribed = () => {
  const navigate = useNavigate();
  
  const [state, setState] = useContext(UserContext);
  const [subscriptions, setSubscriptions] = useState([]);

  const [ready, setReady] = useState(false);
  

  useEffect(() => {
    const getSubscriptions = async () => {
      const { data } = await axios.get("/subscriptions");
//      console.log("subs => ", data);
      //setSubscriptions(data.data);
      setSubscriptions(data);

      if (data.length > 0)
        sendEmail();
      
      setReady(true);
    };

    if (state && state.token) getSubscriptions();
  }, [state && state.token && ready]);

  const manageSubscriptions = async () => {
    const { data } = await axios.get("/customer-portal");
    window.open(data, "_self");
  };

  const sendEmail = async () => {
    const {data} = await axios.post("/sendemail", {
      name: state.user.name,
      email: state.user.email,
      template: "email",
      activation_key: state.user.activation_key,
      subject: "Your Surf Defender Subscription License"
    });
    return(<div></div>);
  }

  
  return !ready ? null : (
     
    <div className="container py-5 mt-5">
      

        { subscriptions.length>0 ?
        <div className="row">

            <h1 className="display-4 text-center">Subscription Successfully Completed</h1>
            <UserOutlined className="display-6" />
            <p className="h5 text-center">{state.user.name}</p>
            <p className="h5 text-center">{state.user.email}</p>
            <p className="lead pb-4 text-center">Thank you! A confirmation email has been sent to you</p>
            <button
                onClick={ () => { navigate(`/account`) } }
                className="btn btn-secondary"
            >Your Account
            </button>
        </div>
        : 
        <div className="row">
            <h1 className="display-4">Subscription Error</h1>
            <UserOutlined className="display-6" />
            <p className="h5 text-center">{state.user.name}</p>
            <p className="h5 text-center">{state.user.email}</p>
            
            <button
                onClick={ () => navigate(`/plans`) }
                className="btn btn-danger"
            >Surf Defender Plans
            </button>
        </div>
        }   
    </div>
  );
  
};

export default Subscribed;
