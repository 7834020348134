import axios from "axios";
import React, { useEffect, useState, useContext, useRef } from "react";
import PriceCard from "../components/cards/PriceCard";
import { isAuth } from "../utils/functions";

import { UserContext } from "../context";
import {useNavigate} from "react-router-dom";
import USDsvg from './images/Usdsvg';
import Swal from 'sweetalert2';

//const Home = ({ history }) => {
const Plans = () => {
  const navigate = useNavigate();
  const [state, setState] = useContext(UserContext);
  const [prices, setPrices] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    //isAuth().then(res => {
    //  if (res==true) {
      fetchPrices();
      fetchProducts();
      //fetchProduct();
    //   } else {
    //     setState({ user: {}, token: "" });
    //     navigate("/login");
    //   }
    // });
  }, []);

  //let bState = state && state.user;
  useEffect( () => {
    
    const check = async () => {
      let result = [];
      if (state && state.user && state.user.subscriptions) {
        const subs = await refreshUserSubscriptions();
        subs.filter( (asub) => asub.status === 'active' || asub.status === 'past_due' ).map( (sub) => {
          result.push(sub.plan.id);
        });
        setUserSubscriptions(result);
      }
      // state &&
      // state.user &&
      // state.user.subscriptions &&
      // state.user.subscriptions.filter( (asub) => asub.status === 'active' || asub.status === 'past_due' ).map( (sub) => {
      //   result.push(sub.plan.id);
      // });

      // state.user.subscriptions.map((sub) => {
      //   result.push(sub.plan.id);
      // });
    }
    isAuth().then(res => {
      if (res==true && state && state.token) {
        check();
      } 
      // else {
      //   setState({ user: {}, token: "" });
      //   navigate("/login");
      // }
    });
       
  }, [state && state.user]);

  // useEffect(() => {
  //   const isPaused = () => {
  //     state &&
  //       state.user &&
  //       state.user.subscriptions &&
  //       state.user.subscriptions.resumes_at &&
        
  //       navigate("/account");
  //   };

  //   state && state.user && isPaused();
  // }, [state && state.user]);

  const fetchPrices = async () => {
    const { data } = await axios.get("/prices");
//    console.log("prices get request", data);
    setPrices(data);

    // const result = [];
    // data.forEach( async (price, index) => {
    //   console.log("price of product:",price);
    //   const product = await axios.get("/single-product", {
    //     product: price.product
    //   })
    //   result.push(product);
    //   console.log("Returned product:", product);
    // })
    // console.log("Product Result:", result)
  };

  const fetchProducts = async () => {
    const { data } = await axios.get("/products");
//    console.log("Products get request:", data);
    setProducts(data);
  };

  const refreshUserSubscriptions = async () => {
    const { data } = await axios.get("/subscriptions");
    return data;
  }

  // const fetchProduct = async () => {
  //   const result = [];
  //   prices.forEach( async (price, index) => {
  //     const product = await axios.get("/single-product", {
  //       prodId: price.product
  //     })
  //     result.push(product);
  //   })
  //   console.log("Product Result:", result)
  // }

  const handleClick = async (e, price) => {
    e.preventDefault();
    if (userSubscriptions && userSubscriptions.includes(price.id)) {
      
      navigate('/account');
      return;
    }
    // console.log("plan clicked", price.id);
    if (state && state.token) {
      var mode = price.recurring ? "subscription" : "payment";
      if (userSubscriptions.length == 0) {
        const { data } = await axios.post("/create-subscription", {
          priceId: price.id,
          mode: mode
        });
        window.open(data, "_self");
      } else {
        Swal.fire({
          title: 'Subscription Request Warning',
          //text: 'You already have an active subscription',
          html:`
            <h4>You already own an active subscription</h4>
            <p>Please use your account or click "Plan Status" button to manage your active subscription</p>
          `,
          icon: 'warning',
          confirmButtonText: 'OK'
        })
        
      }
    } else {

      navigate("/login");
    }
  };

  return (
    <section>
    
    <div className="container-fluid vh-100 plans">
    
      <div className="contentHead row col-md-6 offset-md-3 text-center">
                
        {/* <h1 className="fw-bold pt-1">
          Universal Surf Defender Plans
        </h1> */}

        <h1 className="fw-bold d-block">Universal <span className="textSurf">Surf</span><span className="text-secondary textDefender">Defender </span>Plans</h1>
        
        <span className="bs-icon-md bs-icon-rounded d-flex justify-content-center me-2 bs-icon">
            {USDsvg("6em", "6em")}
        </span>

        <p className="lead pb-1">Choose the right plan for your needs</p>
      </div>

      <div className="contentPlans row pt-1 mb-3 text-center">
        {prices &&
          prices.map((price) => (
            <PriceCard
              key={price.id}
              price={price}
              handleSubscription={handleClick}
              userSubscriptions={userSubscriptions}
              products={products}
            />
          ))}
      </div>
    </div>
    </section>
  );
};

export default Plans;
