import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { UserContext } from "../context";
import USDsvg from '../pages/images/Usdsvg';

const NavCart = () => {
  const [state, setState] = useContext(UserContext);
  const navigate = useNavigate();
  

  const logout = () => {
    setState({ user: {}, token: "" });
    localStorage.removeItem("auth");
    //navigate("/");
    homepage();
  };

  const homepage = () => {
    navigate("/");
  }

  //console.log("STATE => ", state);

  return (
    <section>
      <div className="container">
        <nav id="navCart" className="navbar navbarBV navbar-expand-lg navbar-dark py-0 py-lg-0">
        
          <a href="/" onClick={homepage} className="navbar-brand fs-4">
            <div className="container-fluid d-flex">
              <span className="bs-icon-md bs-icon-rounded mt-1 me-2 bs-icon">
                {USDsvg("3em", "3em")}
              </span>
              <h1 className="text-white fw-bold d-block mt-3 textSurf">Surf<span className="text-secondary textDefender">Defender</span> </h1>
            </div>
          </a>
          

          <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse2">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse2">
            <ul className="navbar-nav ms-3">

              <li className="nav-item">
                <a href="/" onClick={ () => homepage() } className="nav-link" aria-current="page">Home</a>
              </li>

              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/plans">
                  Plans
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/reportissue">
                  Report Issue
                </Link>
              </li> */}

              {state && state.token ? (
                <div className="nav-item dropdown">
                  <a href="#" className="nav-link dropdown-toggle mt-0" id="emaildropdown" role="button" aria-expanded="false" data-bs-toggle="dropdown">
                    {state.user.email}
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="emaildropdown">
                    <li className="dropdown-item" >
                      <Link className="nav-link p-1" to="/account">
                        Account
                      </Link>
                    </li>
                    <li className="dropdown-item" >
                      <Link className="nav-link p-1" to="/reportissue">
                        Report an Issue
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <a href="#" onClick={logout} className="nav-link p-1">
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                <Fragment>
                  <li className="nav-item">
                    <Link className="nav-link" to="/register">
                      Sign up
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/login">
                      Login
                    </Link>
                  </li>
                </Fragment>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default NavCart;
