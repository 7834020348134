
import axios from "axios";


export const isAuth = async () => {
  
  const auth = JSON.parse(localStorage.getItem("auth"));
  if (auth) {
    // check validity
    const { data } = await axios.post("/verifyToken", {
      token: auth.token,
    });
    if (!data.err) {
      
      return true;
    } else {
      
      localStorage.removeItem('auth');
      return false;
    }
  } else {
    return false;
  }
};
