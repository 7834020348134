import React, { useEffect, useState, useContext} from "react";
import { UserContext } from "../context";
import { Link } from "react-router-dom";

const InstallIOS = () => {
    return (
        <section>
            <div className="container-fluid px-0 py-5 mt-5">

                <div className="container mb-4">
                    <div className="text-center mx-auto">
                        <h5 className="text-primary">Surf Defender for Safari</h5>
                        <h6>Enable on iPhone/iPad<br/>(iOS/iPadOS with Safari &ge; 17.2)</h6>
                    </div>
                </div>
                <div className="container ms-0 mb-5">
                    <ul>
                        <li>Go to <strong>Settings</strong> → <strong>Safari</strong> → <strong>Extensions</strong></li>
                        <li>Click on <strong>Universal Surf Defender</strong></li>
                        <li><strong>Turn on the switch</strong> to activate the extension</li>
                        <li><strong>Optionally</strong>, but strongly recommended, allow access to all websites</li>
                        <li>Tap <strong>Settings</strong></li>
                        <li><strong>Log-In</strong> using your <em>Surf Defender Account</em> or <strong>Sign-up</strong> to create one. In the latter case, check the email to <strong>get the <em>Surf Defender License</em> and paste it in the <em>Insert License</em> dialog box</strong></li>
                        
                        <li><em>Surf Defender</em> is now activated.</li>
                    </ul>
                </div>

                <div className="container mb-4">
                    <div className="text-center mx-auto">
                        <h5 className="text-primary">Surf Defender for Safari</h5>
                        <h6>Enable on Mac<br/>(MacOS with Safari &ge; 17.2)</h6>
                    </div>
                </div>
                <div className="container ms-0">
                    <ul>
                        <li>Open Safari and go to <strong>Settings</strong> → <strong>Extensions</strong></li>
                        <li>In the left panel click on <strong>Universal Surf Defender</strong> checkbox to enable</li>
                        <li><strong>Optionally</strong>, but strongly recommended, in the right panel allow access to all websites</li>
                        <li>In the right panel click on <strong>Settings</strong></li>
                        <li><strong>Log-In</strong> using your <em>Surf Defender Account</em> or <strong>Sign-up</strong> to create one. In the latter case, check the email to <strong>get the <em>Surf Defender License</em> and paste it in the <em>Insert License</em> dialog box</strong></li>
                        
                        <li><em>Surf Defender</em> is now activated.</li>
                    </ul>
                </div>

            </div>
        </section>
    )
}

export default InstallIOS;