import React, { useEffect, useState, useContext} from "react";
// import PriceCard from "../components/cards/PriceCard";
import { UserContext } from "../context";
import { Link } from "react-router-dom";
// import USDsvg from './images/Usdsvg';
// import Swal from 'sweetalert2';

const Faqs = () => {

    useEffect(() => {
        document.getElementById('faqs').scrollIntoView();
      }, []);

return (
    <section id="faqs">

        <div className="container-fluid pt-5 px-0">
            <div className="container mt-5 mb-5">
                <div className="text-center mx-auto">
                    <h5 className="text-primary">Surf Defender</h5>
                    <h1>Frequently Asked Questions</h1>
                </div>
            </div>

            <div className="accordion mb-3 pb-5" id="accordionFAQ" >

                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading01">
                    <button className="accordion-button fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse01" aria-controls="collapse01">
                        Why do I have to create an account?
                    </button>
                    </h2>
                    <div id="collapse01" className="accordion-collapse collapse show" aria-labelledby="heading01" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            <p>Surf Defender needs a valid license to work, <strong>Free Trial</strong> or regular <strong>Subscription Plan License</strong>.</p>
                            <p className="text-decoration-underline">We send licenses to our clients via email. For this you need to create a <strong>Surf Defender Account.</strong></p>
                            <p>This can be done in 2 ways:</p>
                            <ul>
                                <li>
                                directly in <strong>Surf Defender</strong> after installation in the browser
                                </li>
                                <li>
                                    <span>using the</span>
                                    <Link to={"/plans"}>
                                        <strong><span className="ms-1 text-decoration-underline">Subscription Plans</span></strong>
                                    </Link>
                                    <span className="ms-1">page</span>
                                </li>
                            </ul>
                            <p>In either case we will send you, in the email you indicated, a Free Trial License with which you can free test Surf Defender immediately for the period indicated in the license. Upon expiration, Surf Defender will stop working.</p>
                            <p>When, at any time, you decide to subscribe to Surf Defender, simply go to the Subscription Plans page, Login using your credentials, choose the plan that suits you best, make the payment and enjoy free surfing with Surf Defender for the entire period covered by the new Subscription Plan License, which will be sent to your email.</p>

                            <p>With your account credentials you will be able to access, for free and lifetime, the <strong>Surf Defender Customer Portal</strong>, where you can check, for example:</p>
                            <ul>
                                <li>
                                    <p>the status of your Trial License</p>
                                </li>
                                <li>
                                    <p>verify, modify or cancel your subscription</p>
                                </li>
                                <li>
                                    <p>view the history of your payments</p>
                                </li>
                                <li>
                                    <p>verify your Subscription Plan License expiry date</p>
                                </li>
                            </ul> 
                            
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading02">
                        <button className="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse02" aria-expanded="false" aria-controls="collapse02">
                            I just installed Surf Defender in my browser. How can I start the Free Trial?
                        </button>
                    </h2>
                    <div id="collapse02" className="accordion-collapse collapse" aria-labelledby="heading02" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                        <p>To take advantage of the <strong>Free Trial</strong> period, after installing Surf Defender you will be prompted to create your own <strong>Surf Defender Account</strong> by entering your name and email in the form shown by Surf Defender in your browser after installation. Our servers will register you and send the Free Trial License to the email you indicated in your account. From that moment you can start testing Surf Defender in your browser for the entire Trial period covered by the sent license. After the Trial period is over, Surf Defender will stop working, waiting for a Subscription Plan License.</p> 
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading03">
                        <button className="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse03" aria-expanded="false" aria-controls="collapse03">
                            How can I subscribe to a Surf Defender Plan?
                        </button>
                    </h2>
                    <div id="collapse03" className="accordion-collapse collapse" aria-labelledby="heading03" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            <p>You can join a Subscription Plan at any time, with or without an active Free Trial or after reinstalling Surf Defender for any reason.</p>
                        
                            <span>Access the</span>
                            <Link to={"/plans"}>
                                <strong><span className="ms-1 text-decoration-underline">Subscription Plans</span></strong>
                            </Link>
                            <span className="ms-1">page, <strong>Login</strong> (if you have already created your Surf Defender account) or <strong>SignUp</strong> (to create your new account), choose the plan that best suits your needs, and make payment. We will immediately send to your account email the Subscription License.</span>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading04">
                        <button className="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse04" aria-expanded="false" aria-controls="collapse04">
                            Do I have to buy a Surf Defender License for each browser I use?
                        </button>
                    </h2>
                    <div id="collapse04" className="accordion-collapse collapse" aria-labelledby="heading04" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            <p><strong>NO</strong>. All licenses (trial/subscription) are <strong>valid for all your browsers</strong> and for the entire period covered by the licenses.</p>
                            <p>Trial License can be used only once and is not renewable.</p>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading05">
                        <button className="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse05" aria-expanded="false" aria-controls="collapse05">
                            Are my payments on Surf Defender website safe and secure?
                        </button>
                    </h2>
                    <div id="collapse05" className="accordion-collapse collapse" aria-labelledby="heading05" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            <p>In two words: <strong>absolutely YES!</strong></p>
                            <p>We know that security is a top concern when making online payments, and we want to assure you that your peace of mind is our priority.</p>
                            
                            <span>Here at</span>
                            <Link to={"/"}>
                                <strong><span className="ms-1 text-decoration-underline">Universalsurfdefender.com</span></strong>
                            </Link>
                            <span className="ms-1">we take the utmost care to ensure the safety and security of your transactions.</span>
                            <p></p>
                            <span>We have partnered with</span>
                            <Link to={"https://stripe.com"} target="_blank">
                                <strong><span className="ms-1 text-decoration-underline">Stripe.com</span></strong>
                            </Link>
                            <span className="ms-1">, a leading and trusted payment gateway, to handle all our online transactions.</span>
                            <p></p>
                            <p>Stripe is renowned for its state-of-the-art security measures, employing industry-leading encryption and fraud prevention technologies to safeguard your sensitive information.</p>

                            <p> Here's why you can trust your payments with us:</p>
                            <ul>
                            <li><strong>Secure Transactions</strong>: Stripe uses advanced encryption protocols to secure your payment data, ensuring that every transaction is processed with the highest level of security.</li>

                            <li><strong>Fraud Prevention</strong>: Our partnership with Stripe enables us to benefit from cutting-edge fraud prevention tools, which continuously monitor and analyze transactions to detect and prevent any suspicious activities.</li>

                            <li><strong>PCI Compliance</strong>: Stripe is fully PCI compliant, adhering to the strictest industry standards for handling payment information. This compliance ensures that your payment details are handled with the utmost care and security.</li>
                            </ul>
                            <p>You can shop with confidence, knowing that your personal and financial information is in safe hands.</p>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading06">
                        <button className="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse06" aria-expanded="false" aria-controls="collapse06">
                            What happens if I cancel my subscription before it expires?
                        </button>
                    </h2>
                    <div id="collapse06" className="accordion-collapse collapse" aria-labelledby="heading06" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            <p>In this case, Surf Defender will continue to work regularly until the scheduled natural expiration of your subscription, which will no longer be automatically renewed and no further charges will be issued. You will still be able to reactivate your subscription at any time BEFORE EXPIRATION from your Surf Defender Account page. After the natural expiration of the cancelled subscription without early reactivation, Surf Defender will cease to function and the subscription will be permanently deleted.</p>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Footer Start */}
            <div id="footer" className="container-fluid footer bg-dark pt-2">
                <div className="container pt-3 pb-4">
                    <div className="row g-5">
                        <div className="col-lg-6 col-md-6">
                            <a href={"/"}>
                                <h1 className="text-white fw-bold d-block textSurf">Surf<span className="text-secondary textDefender">Defender</span> </h1>
                            </a>
                            <p className="mt-3 text-light">Say goodbye to the digital clutter and reclaim your online privacy with our ultimate content blocker designed to revolutionize your browsing experience!</p>
                            <p className="mt-1 text-light">Install Surf Defender now and embrace a secure, ad-free browsing experience!</p>
                            
                        </div>
                        
                        <div className="col-lg-6 col-md-6">
                            <a href={"#footer"} className="h3 text-secondary">Links</a>
                            <div className="mt-4 d-flex flex-column help-link">
                                <a href={"/"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Home</a>
                                <a href={"/terms"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Terms Of Use</a>
                                <a href={"/privacy"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Privacy Policy</a>
                                {/* <a href={"#footer"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Helps</a> */}
                                
                                {/* <a href={"#footer"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Contact</a> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6">
                            <a href={"#footer"} className="h3 text-secondary">Contact Us</a>
                            <div className="text-white mt-4 d-flex flex-column contact-link">
                                
                                <a href={"#footer"} className="py-3 text-light border-bottom border-primary small"><i className="fas fa-envelope text-secondary me-2"></i> universalsurfdefender@gmail.com</a>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="row mt-5 footer-bottom">
                        <div className="col-md-12 text-center">
                            <span className="text-muted small">Some images used on this website were created by Artificial Intelligence</span>
                        </div>
                    </div> */}
                    <hr className="text-light"/>
                    <div className="row footer-bottom">
                        <div className="col-md-6 text-center text-md-start">
                            <span className="text-light"><a href={"#home"} className="text-secondary"><i className="fas fa-copyright text-secondary me-2"></i>Universal Software</a>, All right reserved.</span>
                        </div>
                        <div className="col-md-6 text-center text-md-end">                
                            <span className="text-muted">Distributed By <a href="https://universalsurfdefender.com">Universal Surf Defender</a></span>
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* Footer End */}
        </div>
    </section>
) 
};

export default Faqs;