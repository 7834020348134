import React, { useEffect, useState, useContext} from "react";
// import PriceCard from "../components/cards/PriceCard";
import { UserContext } from "../context";
import { Link } from "react-router-dom";
// import USDsvg from './images/Usdsvg';
// import Swal from 'sweetalert2';

const Terms = () => {

return (
    <section>
        <div className="container-fluid px-0">
            <div className="container py-5 mt-5">
                <div className="text-center mx-auto">
                    <h5 className="text-primary">Surf Defender</h5>
                    <h1>Terms of Use</h1>
                </div>
            </div>

            <div className="container ms-4 me-2 mb-5">
                <h1>End User License Agreement</h1>

                <p>Surf Defender - https://universalsurfdefender.com,<br/>
                Copyright © 2022-2023, Universal Software, LLC.</p>

                <p>This software is proprietary. Reuse, modification, compensation, or redistribution without written consent from
                the copyright holder is prohibited under USA law and applicable international copyright treaties.</p>

                <p>THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES,
                INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE
                DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR
                SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY,
                WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE
                USE OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. YOU ARE FREE TO UNINSTALL AT ANY TIME IN
                ORDER TO RESTORE YOUR ORIGINAL SETTINGS.</p>

                <p>Updated December 22, 2023.</p>
            </div>
        </div>

        {/* Footer Start */}
        <div id="footer" className="container-fluid footer bg-dark pt-2">
            <div className="container pt-3 pb-4">
                <div className="row g-5">
                    <div className="col-lg-6 col-md-6">
                        <a href={"/"}>
                            <h1 className="text-white fw-bold d-block textSurf">Surf<span className="text-secondary textDefender">Defender</span> </h1>
                        </a>
                        <p className="mt-3 text-light">Say goodbye to the digital clutter and reclaim your online privacy with our ultimate content blocker designed to revolutionize your browsing experience!</p>
                        <p className="mt-1 text-light">Install Surf Defender now and embrace a secure, ad-free browsing experience!</p>
                        
                    </div>
                    
                    <div className="col-lg-6 col-md-6">
                        <a href={"#footer"} className="h3 text-secondary">Links</a>
                        <div className="mt-4 d-flex flex-column help-link">
                            <a href={"/"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Home</a>
                            <a href={"/terms"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Terms Of Use</a>
                            <a href={"/privacy"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Privacy Policy</a>
                            {/* <a href={"#footer"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Helps</a> */}
                            
                            {/* <a href={"#footer"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Contact</a> */}
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                        <a href={"#footer"} className="h3 text-secondary">Contact Us</a>
                        <div className="text-white mt-4 d-flex flex-column contact-link">
                            
                            <a href={"#footer"} className="py-3 text-light border-bottom border-primary small"><i className="fas fa-envelope text-secondary me-2"></i> universalsurfdefender@gmail.com</a>
                        </div>
                    </div> */}
                </div>
                {/* <div className="row mt-5 footer-bottom">
                    <div className="col-md-12 text-center">
                        <span className="text-muted small">Some images used on this website were created by Artificial Intelligence</span>
                    </div>
                </div> */}
                <hr className="text-light"/>
                <div className="row footer-bottom">
                    <div className="col-md-6 text-center text-md-start">
                        <span className="text-light"><a href={"#home"} className="text-secondary"><i className="fas fa-copyright text-secondary me-2"></i>Universal Software</a>, All right reserved.</span>
                    </div>
                    <div className="col-md-6 text-center text-md-end">                
                        <span className="text-muted">Distributed By <a href="https://universalsurfdefender.com">Universal Surf Defender</a></span>
                    </div>
                    
                </div>
            </div>
        </div>
        {/* Footer End */}
    </section>
)
}

export default Terms;