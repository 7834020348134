import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../context";
import moment from "moment";
import {useNavigate, Navigate} from "react-router-dom";
import { isAuth } from "../utils/functions";
import axios from "axios";
import { toast } from "react-hot-toast";
import Button from "../components/Button";

const ReportIssue = () => {
    const navigate = useNavigate();
    const [state, setState] = useContext(UserContext);

    const [ready, setReady] = useState(false);
    const titleRef = useRef()
    const bodyRef = useRef()
    const versionRef = useRef()
    const versionInstalled = useRef()
    const deviceOs = useRef()

    const handleClick = async (e) => {
        try {
            let t = titleRef.current.value || ""
            let b = bodyRef.current.value || ""
            let v = versionRef.current.value || ""
            let i = versionInstalled.current.value || ""
            let o = deviceOs.current.value || ""

            //console.log ("Fields:", t,b,v,i,o)

            if (t.trim() === "" || b.trim() === "" || v.trim() === "" || i.trim() === "" || o.trim() === "") {
                toast.error("Please fill in all the needed fields")

                if (v.trim() === "") { versionRef.current?.focus() } else
                if (i.trim() === "") { versionInstalled.current?.focus() } else
                if (o.trim() === "") { deviceOs.current?.focus() } else
                if (t.trim() === "") { titleRef.current?.focus() } else
                if (b.trim() === "") { bodyRef.current?.focus() }
                
                
            
            } else {
                b = b.replace("\n", "<br />")
                const { data } = await axios.post("/repissue", {
                    title:`${t.replace(/"/g, "'")}`,
                    body: `<b>Reporter:</b> ${state.user.name} - ${state.user.email}<br /><b>USD Info:</b> <i>Version</i>: ${v.trim()} - <i>Browser</i>: ${i.trim()} - <i>OS</i>: ${o.trim()}<br /><b>Sender Environ:</b> ${navigator.userAgent.toLowerCase()}<br /><br />${b.replace(/"/g, "'")}`
                });
                if (data.error) {
                    toast.error("An error occurred while creating the issue report!");
                } else {
                    toast.success(
                        `Issue report successfully sent. Thank you!`
                    );
                }
                console.log("From submit report:", data);
                navigate("/");
            }
        }
        catch(err) {
            //      console.log(err);
            toast.error("Something went wrong. Try again");
        }
    }

    useEffect(() => {
        
        isAuth().then(res => {
          if (res==true && state && state.token) {
          
            setReady(true);

          } else {
            setState({ user: {}, token: "" });
            navigate("/login", {state:{page:"/reportissue"}} );
            //return (<Navigate to="/login" state={{page:"/reportissue"}} />)
            
          }
    
        });
        
    }, [state && state.token && ready]);
    
    useEffect(() => {
        const inputField = document.getElementById('new_myField');
    },[])

    return !ready ? null : (
    <div className="container contentHead">
        <section>
            <div className="container mt-5 mb-1">
                <div className="text-center mx-auto">
                    <h5 className="text-primary">Surf Defender</h5>
                    <h1>Report an Issue</h1>
                </div>
            </div>
            <div className="container me-2">
                <h5 className="text-primary">The Reporter</h5>
                <div className="input-group ms-3 mb-1">
                    <label className="input-group-text" htmlFor="userName">User Name</label>
                    <input type="text" className="form-control" id="userName" aria-describedby="userHelp" placeholder="Your Name" value={state.user.name} readOnly/>
                </div>
                <div className="input-group ms-3 mb-2">
                    <label className="input-group-text" htmlFor="userEmail">User Email</label>
                    <input type="email" className="form-control" id="userEmail" aria-describedby="emailHelp" placeholder="Enter email" value={state.user.email} readOnly/>
                </div>

                {/* <div className="input-group mb-3">
                    <label className="input-group-text" htmlFor="userBrowser">User Environment</label>
                    <input type="text" className="form-control" id="userBrowser" aria-describedby="browserHelp" placeholder="Enter Browser" value={navigator.userAgent.toLowerCase()} readOnly/>
                </div> */}
                
                <h5 className="text-primary mt-3">Surf Defender Information</h5>
                    <div className="input-group ms-3 mb-1">
                        <label className="input-group-text" htmlFor="USDVersion">Surf Defender Version</label>
                        <input autoFocus type="text" className="form-control" id="USDVersion" ref={versionRef} placeholder="Installed version" required/>
                    </div>
                    <div className="input-group ms-3 mb-1">
                        <label className="input-group-text ms-1" htmlFor="userBrowserType">Installed in</label>
                        <select ref={versionInstalled} className="form-select" id="userBrowserType">
                            <option defaultValue="" disabled>Please select...</option>
                            <option value="Chrome-Chromium">Chrome/Chromium</option>
                            <option value="Firefox">Firefox</option>
                            <option value="Safari">Safari</option>
                        </select>
                        
                        </div>
                        <div className="input-group ms-3 mb-2">

                        <label className="input-group-text ms-1" htmlFor="userDeviceOS">Device Operating System</label>
                        <select ref={deviceOs} className="form-select" id="userDeviceOS">
                            <option defaultValue="" disabled>Please select...</option>
                            <option value="Windows">Windows</option>
                            <option value="Mac">Mac OS X</option>
                            <option value="iOS">iOS</option>
                        </select>
                    </div>
                

                <h5 className="text-primary mt-3">The Issue Report</h5>
                <div className="input-group ms-3">
                    <label className="input-group-text" htmlFor="issueTitle">Issue Title</label>
                    <input type="text" className="form-control" id="issueTitle" ref={titleRef} aria-describedby="titleHelp" placeholder="Type a title for your issue report" required/>
                </div>

                <div className="input-group ms-3">
                    <textarea className="form-control mt-1 mb-2" id="issueBody" ref={bodyRef} aria-describedby="bodyHelp" placeholder="Describe the issue to report" rows="3" required/>
                </div>
                
                <div className="input-group ms-3 mb-2 d-grid">
                    <div className="d-grid">
                        <Button
                            handleclick={handleClick}
                            type="danger"
                            text="Send Report"
                            size="lg"
                        />
                    </div>
                </div>
            </div>
        </section>
    </div>
    )
}

export default ReportIssue;



