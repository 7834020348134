import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context";
import USDsvg from '../pages/images/Usdsvg';
import { HashLink } from 'react-router-hash-link';

const NavFaqs = () => {
  const [state, setState] = useContext(UserContext);
  const navigate = useNavigate();
  
  const [click, setClick] = useState(false)

  const logout = () => {
    setState({ user: {}, token: "" });
    localStorage.removeItem("auth");
    //navigate.push("/login");
    
    navigate("/");
  };

  
  return (
    <section>
        <div className="container">
            <nav className="autohide navbar navbarBV navbar-expand-lg navbar-light py-0 py-lg-0">
              
                {/* <a href={"#home"} className="navbar-brand fs-4"> */}
                <Link to="/" className="navbar-brand fs-4">
                    {/* <img className="img-fluid" src="img/logo.png" alt="Logo"/> */}

                      <div className="container-fluid d-flex">
                        <span className="bs-icon-md bs-icon-rounded mt-1 me-2 bs-icon">
                          {USDsvg("3em", "3em")}
                        </span>
                        
                          <h1 className="text-white fw-bold d-block mt-3 textSurf">Surf<span className="text-secondary textDefender">Defender</span> </h1>
                          {/* <span className="navbar-text textSurf me-2 mt-2">Surf</span>               
                          <span className="navbar-text textDefender mt-2 text-white">Defender</span> */}
                        
                       
                      </div>

                </Link>    
                {/* </a> */}
                
                <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">
                    
                  <ul className={click ? "navbar-nav ms-3 active" : "navbar-nav ms-3"}>
                  
                    <li className="nav-item">
                      {/* <a href={"#home"} className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Home</a> */}
                      <Link to="/"><span className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Home</span></Link>
                    </li>
                                            
                    {state && state.token ? (
                      <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle mt-0" id="emaildropdown" role="button" aria-expanded="false" data-bs-toggle="dropdown">
                          {state.user.email}
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="emaildropdown">
                          <li className="dropdown-item" >
                            <Link className="nav-link p-1" to="/account">
                              Account
                            </Link>
                          </li>
                          <li className="dropdown-item" >
                            <Link className="nav-link p-1" to="/reportissue">
                              Report an Issue
                            </Link>
                          </li>
                          <li className="dropdown-item">
                            <a href="#" onClick={logout} className="nav-link p-1">
                              Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <Fragment>
                        
                          <Link className="nav-item nav-link" to="/plans">
                            Subscribe
                          </Link>
                        
                        
                          {/* <Link className="nav-item nav-link" to="/login">
                            Log In
                          </Link> */}
              
                      </Fragment>
                    )}
                  </ul>
                </div>
            </nav>
        </div>
    {/* </div> */}
    {/* Navbar End */}
    </section>
  )
}

export default NavFaqs;