import React from "react";

const Input = ({ label, value, setValue, type,  placeholder = "text", addonHtml=null, handleclick=null, id = ""}) => (
  <div className="input-group mb-3">
    <span className="input-group-text">{label}</span>
    <input
      type={type}
      //onChange={(e) => setValue(e.target.value)}
      onChange={(e) => setValue(handleKeyPress(e, {type}))}
      value={value}
      id={id}
      className="form-control"
      placeholder={placeholder}
    />
    {/* <element dangerouslySetInnerHTML={ {__html: addonHtml} }></element> */}
    {addonHtml ? <span className="input-group-addon"><span onClick={handleclick}><i className="fas fa-eye-slash text-secondary ms-2 mt-2 py-1" role="button" id="togglePassword"></i></span></span> : <></>}
  </div>
);

function handleKeyPress(e, type) {
  //console.log("TYPE = ", type);

  var allowedRegex = /[^a-z0-9\-]/gi; // License
  if (type.type === 'email') {
    allowedRegex = /[^a-z0-9@\-_\.]/g; // email
  } else if (type.type === 'password') {
    allowedRegex = /[^a-z0-9&!$?%@=\^\-_+*\.]/gi; // password
  }
  
  let txtOrig = e.target.value;
  let txtFinal = txtOrig.replace(allowedRegex, '');
  if (type.type === 'license') {
    txtFinal = txtFinal.toUpperCase();
  }
  if (txtOrig !== txtFinal) {
    // do something like alert or show a message (wrong characters typed or pasted)
  }
  return txtFinal;
}

export default Input;
