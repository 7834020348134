import React, { useState, useContext } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import axios from "axios";
import { toast } from "react-hot-toast";
import { UserContext } from "../context";
import {useNavigate, Link, useLocation} from "react-router-dom";
import USDsvg from './images/Usdsvg';
import "../css/spinner.css";

const Login = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const page = location?.state?.page || "/plans";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  // context
  const [state, setState] = useContext(UserContext);

  const handleClick = async (e) => {
    // console.log("email and password", email, password);
    try {
      e.preventDefault();
      const spinner = document.getElementById('cover-spin');
      spinner.style.display = "block";

      const { data } = await axios.post("/login", {
        email,
        password,
      });
//      console.log(data);

      spinner.style.display = "none";

      if (data.error) {
        toast.error(data.error);
      } else {
        setEmail("");
        setPassword("");
        setState(data);
        localStorage.setItem("auth", JSON.stringify(data));

        //console.log("LOCATION:", page)
        
        navigate(page);
          
      }
    } catch (err) {
//      console.log(err);
      toast.error("Something went wrong. Try again");
    }
  };

  const togglePassword = (e) => {
    const input = document.getElementById('signPassword'); 
    const icon = document.getElementById('togglePassword'); 
    const type = input.getAttribute("type") === "password" ? "text" : "password";
    const classicon = type === 'password' ? "fa-eye-slash" : "fa-eye";

    input.setAttribute("type", type);
    
    icon.classList.remove("fa-eye", "fa-eye-slash")
    icon.classList.add(classicon);
    //icon.classList.toggle("fa-eye");
  }

  return (
    <section className="py-5 mt-5">
    <div className="d-flex justify-content-center" style={{ height: "80vh" }}>
      <div id="cover-spin" style={{display: "none"}}></div>
      
      <div className="container align-items-center d-flex">
        <div className="row col-md-7 offset-md-2 text-center">
          <h1 className="pt-5 fw-bold">Login</h1>
          <span className="bs-icon-md bs-icon-rounded d-flex justify-content-center me-2 bs-icon">
            {USDsvg("6em", "6em")}
          </span>
          <p className="fs-6 pb-4">
            Access your subscriptions. Anytime. Anywhere.
          </p>

          <div className="form-group">
            <Input
              label="Email"
              type="email"
              value={email}
              placeholder="Your account email"
              setValue={setEmail}
            />
            <Input
              label="Password"
              type="password"
              value={password}
              id="signPassword"
              placeholder="Your account password"
              setValue={setPassword}
              handleclick={togglePassword}
              addonHtml='something'
            />

            <div className="text-center">
              <p className="card-text fs-6 mb-3">Forgot your password? <Link className="card-text" to="/reset">Reset Password</Link></p> 
            </div>

            <div className="d-grid">
              <Button
                handleclick={handleClick}
                type="danger"
                text="Login"
                size="lg"
              />
            </div>
            <div className="text-center">
              <p className="card-text fs-6">Don't have an account yet? <Link className="card-text" to="/register">Sign Up</Link> for free</p> 
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Login;
