// import axios from "axios";
import React, { useEffect, useState, useContext} from "react";
// import PriceCard from "../components/cards/PriceCard";
import { UserContext } from "../context";
import { Link } from "react-router-dom";
// import USDsvg from './images/Usdsvg';
// import Swal from 'sweetalert2';
import AOS from 'aos';
import { HashLink } from 'react-router-hash-link';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const WebStart = () => {
    //const history = useNavigate();
    const [state, setState] = useContext(UserContext);
    const [showtop, setShowTop] = useState(false);

    useEffect(() => {
        setTimeout(function () {
            try {
            document.getElementsByClassName('carousel-control-next-icon')[0].click();
            }
            catch(ex) {}
       }, 5000); 
    }, []);

    useEffect(() => {
        document.getElementById('home').scrollIntoView();
      }, []);

      useEffect(() => {
        const setGoTop = () => {
            if (window.scrollY >= 300) {
            setShowTop(true)
           } else {
            setShowTop(false)
          }
        }
        window.addEventListener('scroll', setGoTop);
      }, []);

    const optionsTeam = {
        autoplay: true,
        smartSpeed: 1000,
        center: false,
        dots: false,
        loop: true,
        margin: 50,
        nav : true,
        navText : [
            '<i className="bi bi-arrow-left"></i>',
            '<i className="bi bi-arrow-right"></i>'
        ],
        responsiveClass: true,
        responsive: {
            0:{
                items:1
            },
            768:{
                items:2
            },
            992:{
                items:3
            }
        }
    }

    // const optionsTestimonials = {
    //     autoplay: true,
    //     smartSpeed: 1500,
    //     center: true,
    //     dots: true,
    //     loop: true,
    //     margin: 0,
    //     nav : true,
    //     navText: false,
    //     responsiveClass: true,
    //     responsive: {
    //         0:{
    //             items:1
    //         },
    //         576:{
    //             items:1
    //         },
    //         768:{
    //             items:2
    //         },
    //         992:{
    //             items:3
    //         }
    //     }
    // }

    useEffect(() => {
        AOS.init();

    },[]);



return (
    <section id="home">

    {/* Carousel Start */}
    <div className="container-fluid px-0">
        <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
            {/*
            <ol className="carousel-indicators">
                <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active" aria-current="true" aria-label="First slide"></li>
                <li data-bs-target="#carouselId" data-bs-slide-to="1" aria-label="Second slide"></li>
            </ol>
            */}
            {/* <div className="carousel-inner" role="listbox"> */}
            <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="6000">
                    
                    <img src="img/carousel-1.jpg" className="img-fluid d-block w-100" alt="First slide"/>
                    <div className="carousel-caption centerCaption">
                        <div className="container-fluid carousel-content w-100">
                            {/* <h6 className="text-secondary h4 animated fadeInUp">Best IT Solutions</h6> */}
                            <h1 className="text-white display-1 animated fadeInRight">Don't drown</h1>
                            <h1 className="text-white display-1 animated fadeInRight">in a sea of advertising...</h1>
                            {/*
                            <p className="mb-4 text-white fs-5 animated fadeInDown">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Pellentesque aliquam dolor eget urna ultricies tincidunt.</p>
                            <a href="" className="me-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></a>
                            <a href="" className="ms-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact Us</button></a>
                            */}
                        </div>
                    </div>
                </div>
                <div className="carousel-item" data-bs-interval="9000">

                    <img src="img/carousel-2.png" className="img-fluid d-block w-100" alt="Second slide"/>
                    <div className="carousel-caption rightCaption">
                        <div className="container carousel-content py-4">
                            {/* <h6 className="text-secondary h4 animated fadeInUp">Best IT Solutions</h6> */}
                            <h1 className="text-white display-2 animated fadeInLeft">Arm your browser with</h1>
                            <h1 className="text-white display-1 animated fadeInLeft textSurf">Surf<span className="text-secondary textDefender">Defender</span> </h1>
                            {/* <p className="mb-4 text-white fs-5 animated fadeInDown">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Pellentesque aliquam dolor eget urna ultricies tincidunt.</p> */}
                            
                            <div className="d-grid gap-2 d-md-block">
                                <a href={"#about"}><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-sm btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></a>
                                <Link to="/plans">
                                    <button type="button" className="ms-2 px-4 py-sm-3 px-sm-5 btn btn-sm btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Subscribe Now</button>                            
                                </Link>
                                {/* <a href={"/plans"} className="ms-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Subscribe Now</button></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    {/* Carousel End */}

    {/* <div className="container-fluid bg-transparent py-2 d-none d-md-flex">
        <div className="container">
            <div className="d-flex justify-content-between topbar">
                <div id="note" className="text-secondary d-none d-xl-flex"><small>Note: all the images in this website have been created by Artificial Intelligence</small></div>
            </div>
        </div>
    </div> */}
    
    {/* About Start */}
    <div id="about" className="py-3"></div>
    <div className="container-fluid pb-4">
        <div className="container">
            <div className="text-center mx-auto pb-4" data-aos="fade-in" data-aos-duration="300" style={{maxWidth: "600px"}}>
                <h5 className="text-primary">About Surf Defender</h5>
                <h1>What is Surf Defender</h1>
            </div>
            <div className="row g-5">
                {/* <div className="col-lg-5 col-md-6 col-sm-12" data-aos="fade-in" data-aos-duration="300"> */}
                <div className="col-lg-5 col-md-6 col-sm-12">
                    <div className="h-100 position-relative">
                        <img src="img/about-1.jpg" className="img-fluid w-75 rounded" alt="" style={{marginBottom: "25%"}}/>
                        <div className="position-absolute w-75" style={{top: "25%", left: "25%"}}>
                            <img src="img/about-2.jpg" className="img-fluid w-100 rounded" alt=""/>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-7 col-md-6 col-sm-12" data-aos="fade-in" data-aos-duration="500"> */}
                <div className="col-lg-7 col-md-6 col-sm-12">
                    <p>Surf Defender is the most powerful and versatile content blocker to date on the market.</p> 
                    <p>It comes in the form of an extension for browsers and, once installed, removes annoying pop-up and banner ads, disruptive video ads and dangerous trackers from the pages opened in your browser, allowing for faster, safer and freer browsing. </p>
                    <p>Surf Defender uses the most advanced technology and interception techniques to fight against unwanted advertising and trackers and is constantly updated to allow for increasingly relaxed, fast and safe surfing.</p>
                    <p>After installing Surf Defender in your browser, you will realize how much smoother, faster and distraction-free your surfing has become. At the same time it will preserve your privacy by preventing trackers from transmitting confidential information who knows to whom and why.</p>
                    <p className="mb-4">Surf Defender can be installed on Google Chrome and several Chromium based browsers, Edge, Kiwi for Android, Firefox, Firefox for Android and Safari for Mac, iPhone and iPad.</p>
                    <p>Install it now on your favorite browser and enjoy your surfing, finally free from annoyances, impositions and unauthorized intrusions.</p>
                    {/* <a href={"/plans"} className="btn btn-secondary rounded-pill px-5 py-3 text-white">Subscribe Now</a> */}
                    <HashLink to="#getit">
                        <button type="button" className="mb-1 me-1 px-5 py-3 py-sm-3 px-sm-5 rounded-pill btn btn-primary">Get It!</button>                            
                    </HashLink>
                    <Link to="/plans">
                        <button type="button" className="px-5 py-3 py-sm-3 px-sm-5 rounded-pill btn btn-secondary">Subscribe Now</button>                            
                    </Link>
                </div>
            </div>
        </div>
    </div>
    {/* About End */}


    {/* Features Start */}
    <div id="features" className="py-4 mb-4"></div>
    <div className="container-fluid pt-5 pb-4 features">
        <div className="container">
            <div className="text-center mx-auto pb-4" data-aos="fade-in" data-aos-duration="300" style={{maxWidth: "600px"}}>
                <h5 className="text-primary">Surf Defender Features</h5>
                <h1>What Surf Defender Does</h1>
            </div>
            <div className="row g-5 features-inner">
                <div className="col-md-6 col-lg-4" data-aos="fade-in" data-aos-duration="300">
                    <div className="features-item bg-light">
                        <div className="p-4 text-center features-content">
                            <div className="features-content-icon">
                                <i className="fa-solid fa-bullhorn fa-7x mb-4 text-primary"></i>
                                <h4 className="mb-3">Browse Without Advertising</h4>
                                <p className="mb-4">Bid farewell to annoying pop-ups, flashy banners, and disruptive video ads that clutter your screen, transforming your surfing in a nightmare. Surf Defender efficiently identifies and blocks all types of unwanted ads, ensuring a seamless and distraction-free online journey.</p>
                                {/* <a href={"/plans"} className="btn btn-secondary text-white px-5 py-3 rounded-pill">Subscribe Now</a> */}
                                <Link to="/plans">
                                    <button type="button" className="px-5 py-3 py-sm-3 px-sm-5 rounded-pill btn btn-secondary">Subscribe Now</button>                            
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-in" data-aos-duration="500">
                    <div className="features-item bg-light">
                        <div className="p-4 text-center features-content">
                            <div className="features-content-icon">
                                <i className="fas fa-eye-slash fa-7x mb-4 text-primary"></i>
                                <h4 className="mb-3">Remove Trackers</h4>
                                <p className="mb-4">Concerned about being constantly tracked across websites? Surf Defender takes your privacy seriously! Our state-of-the-art tracking prevention technology shields you from prying eyes, keeping your online activities confidential and secure.</p>
                                {/* <a href={"/plans"} className="btn btn-secondary text-white px-5 py-3 rounded-pill">Subscribe Now</a> */}
                                <Link to="/plans">
                                    <button type="button" className="px-5 py-3 py-sm-3 px-sm-5 rounded-pill btn btn-secondary">Subscribe Now</button>                            
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-in" data-aos-duration="700">
                    <div className="features-item bg-light">
                        <div className="p-4 text-center features-content">
                            <div className="features-content-icon">
                                <i className="fas fa-truck-fast fa-7x mb-4 text-primary"></i>
                                <h4 className="mb-3">Speed Up Browsing</h4>
                                <p className="mb-4">Experience the internet at warp speed! Surf Defender not only blocks ads and trackers but also optimizes your browsing speed. Enjoy a faster, smoother online experience without the unnecessary baggage of resource-hogging advertisements.</p>
                                {/* <a href={"/plans"} className="btn btn-secondary text-white px-5 py-3 rounded-pill">Subscribe Now</a> */}
                                <Link to="/plans">
                                    <button type="button" className="px-5 py-3 py-sm-3 px-sm-5 rounded-pill btn btn-secondary">Subscribe Now</button>                            
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-in" data-aos-duration="300">
                    <div className="features-item bg-light">
                        <div className="p-4 text-center features-content">
                            <div className="features-content-icon">
                                <i className="fas fa-user-secret fa-7x mb-4 text-primary"></i>
                                <h4 className="mb-3">Malware Defense</h4>
                                <p className="mb-4">Worried about malicious ads that could compromise your device? Fear not! Surf Defender acts as your digital shield, blocking harmful ads and protecting your device from potential malware threats. Your safety is our top priority.</p>
                                {/* <a href={"/plans"} className="btn btn-secondary text-white px-5 py-3 rounded-pill">Subscribe Now</a> */}
                                <Link to="/plans">
                                    <button type="button" className="px-5 py-3 py-sm-3 px-sm-5 rounded-pill btn btn-secondary">Subscribe Now</button>                            
                                </Link>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-in" data-aos-duration="500">
                    <div className="features-item bg-light">
                        <div className="p-4 text-center features-content">
                            <div className="features-content-icon">
                                <i className="fas fa-thumbs-up fa-7x mb-4 text-primary"></i>
                                <h4 className="mb-3">Cross-Platform Compatibility</h4>
                                <p className="mb-4">Whether you're surfing the web on your desktop, tablet, or smartphone, Surf Defender seamlessly integrates across all platforms. Enjoy consistent protection and ad-free browsing on all your devices.</p>
                                {/* <a href={"/plans"} className="btn btn-secondary text-white px-5 py-3 rounded-pill">Subscribe Now</a> */}
                                <Link to="/plans">
                                    <button type="button" className="px-5 py-3 py-sm-3 px-sm-5 rounded-pill btn btn-secondary">Subscribe Now</button>                            
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-in" data-aos-duration="700">
                    <div className="features-item bg-light">
                        <div className="p-4 text-center features-content">
                            <div className="features-content-icon">
                                <i className="fas fa-laptop fa-7x mb-4 text-primary"></i>
                                <h4 className="mb-3">Free Regular Updates</h4>
                                <p className="mb-4">Our dedicated team is committed to staying one step ahead of the evolving online landscape. Surf Defender receives regular updates to ensure continuous compatibility with the latest web technologies and emerging threats.</p>
                                {/* <a href={"/plans"} className="btn btn-secondary text-white px-5 py-3 rounded-pill">Subscribe Now</a> */}
                                <Link to="/plans">
                                    <button type="button" className="px-5 py-3 py-sm-3 px-sm-5 rounded-pill btn btn-secondary">Subscribe Now</button>                            
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Features End */}


    {/* GetIt Start */}
    <div id="getit" className="py-4"></div>
    <div className="container-fluid pt-5 pb-4">
        <div className="container project">
            <div className="text-center mx-auto pb-4" data-aos="fade-in" data-aos-duration="300" style={{maxWidth: "600px"}}>
                <h5 className="text-primary">Get It!</h5>
                <h1>Get Surf Defender</h1>
                <h2>for Your Browser</h2>
            </div>
            <div className="row">
                <div className="col-md-1 col-lg-1"></div>
                <div className="col-md-10 col-lg-10" data-aos="fade-in" data-aos-duration="500">
                    <p>Install Surf Defender in your favorite browser by clicking on the corresponding button below.</p>

                    <p>We provide you with a <strong>Free Trial</strong> period, during which you can test Surf Defender.</p>
                    <span>Otherwise you can join one of the available</span>
                    <strong>
                    <Link to={"/plans"}>
                            <span className="ms-1 text-decoration-underline">Subscription Plans</span>
                    </Link>
                    </strong>
                    <span className="ms-1">right now.</span>
                    <p></p>
                    <p>Whether you decide to take advantage of the Free Trial period or join one of the Subscription Plans, you must create a <strong>Surf Defender Account</strong>.</p>
                    
                    <p className="text-center">
                    <strong>
                    <span>Get more information in the</span>
                    <Link to={"/faqs"}>
                            <span className="ms-1 text-decoration-underline">FAQ page</span>
                    </Link>
                    </strong>
                    </p>
                    
                    <h6 className="mt-3 mb-5 text-center">Happy surfing with Surf Defender</h6>
                </div>
                <div className="col-md-1 col-lg-1"></div>
            </div>
            <div className="row g-5">
                <div className="col-md-6 col-lg-3" data-aos="fade-in" data-aos-duration="300">
                    <div className="project-item">
                        <div className="project-img">
                            <img src="img/getit-Google.png" className="img-fluid rounded" alt=""/>
                            <div className="project-content">
                                <a href={"#home"} className="text-center">
                                    <h4 className="text-secondary">SurfDefender for Chromium</h4>
                                    <p className="m-0 text-white">Get It Now</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3" data-aos="fade-in" data-aos-duration="500">
                    <div className="project-item">
                        <div className="project-img">
                            <img src="img/getit-Firefox.png" className="img-fluid w-100 rounded" alt=""/>
                            <div className="project-content">
                                <a href={"#home"} className="text-center">
                                    <h4 className="text-secondary">SurfDefender for Firefox</h4>
                                    <p className="m-0 text-white">Get It Now</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3" data-aos="fade-in" data-aos-duration="700">
                    <div className="project-item">
                        <div className="project-img">
                            <img src="img/getit-Microsoft.png" className="img-fluid w-100 rounded" alt=""/>
                            <div className="project-content">
                                <a href={"#home"} className="text-center">
                                    <h4 className="text-secondary">SurfDefender for Edge</h4>
                                    <p className="m-0 text-white">Get It Now</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3" data-aos="fade-in" data-aos-duration="300">
                    <div className="project-item">
                        <div className="project-img">
                            <img src="img/getit-Safari.png" className="img-fluid w-100 rounded" alt=""/>
                            <div className="project-content">
                                <a href={"#home"} className="text-center">
                                    <h4 className="text-secondary">SurfDefender for Safari</h4>
                                    <p className="m-0 text-white">Get It Now</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-6 col-lg-4" data-aos="fade-in" data-aos-duration="500">
                    <div className="project-item">
                        <div className="project-img">
                            <img src="img/project-5.jpg" className="img-fluid w-100 rounded" alt=""/>
                            <div className="project-content">
                                <a href="#" className="text-center">
                                    <h4 className="text-secondary">Digital Marketing</h4>
                                    <p className="m-0 text-white">Marketing Analysis</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-in" data-aos-duration="700">
                    <div className="project-item">
                        <div className="project-img">
                            <img src="img/project-6.jpg" className="img-fluid w-100 rounded" alt=""/>
                            <div className="project-content">
                                <a href="#" className="text-center">
                                    <h4 className="text-secondary">keyword Research</h4>
                                    <p className="m-0 text-white">keyword Analysis</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    {/* GetIt End */}


    
    {/* Team Start */}
    <div id="team" className="py-4"></div>
    <div className="container-fluid pt-5 pb-4 team">
        <div className="container">
            <div className="text-center mx-auto pb-4" data-aos="fade-in" data-aos-duration="300" style={{maxWidth: "600px"}}>
                <h5 className="text-primary">Our Team</h5>
                <h1>Meet our Team</h1>
            </div>
            <OwlCarousel {...optionsTeam} data-aos="fade-in" data-aos-duration="500">
            {/* <div className="team-carousel wow fadeIn" data-wow-delay=".5s"> */}
                <div className="rounded team-item">
                    <div className="team-content">
                        <div className="team-img-icon">
                            <div className="team-img rounded-circle">
                                <img src="img/team-1.jpg" className="img-fluid w-100 rounded-circle" alt=""/>
                            </div>
                            <div className="team-name text-center py-3">
                                <h4 className="">Andrea</h4>
                                <p className="m-0">Software Engineer</p>
                            </div>
                            {/* <div className="team-icon d-flex justify-content-center pb-4">
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-linkedin-in"></i></a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="rounded team-item">
                    <div className="team-content">
                        <div className="team-img-icon">
                            <div className="team-img rounded-circle">
                                <img src="img/team-2.jpg" className="img-fluid w-100 rounded-circle" alt=""/>
                            </div>
                            <div className="team-name text-center py-3">
                                <h4 className="">Roberto</h4>
                                <p className="m-0">Software Engineer</p>
                            </div>
                            {/* <div className="team-icon d-flex justify-content-center pb-4">
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-linkedin-in"></i></a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="rounded team-item">
                    <div className="team-content">
                        <div className="team-img-icon">
                            <div className="team-img rounded-circle">
                                <img src="img/team-3.jpg" className="img-fluid w-100 rounded-circle" alt=""/>
                            </div>
                            <div className="team-name text-center py-3">
                                <h4 className="">Adelina</h4>
                                <p className="m-0">Projects Manager</p>
                            </div>
                            {/* <div className="team-icon d-flex justify-content-center pb-4">
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-linkedin-in"></i></a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="rounded team-item">
                    <div className="team-content">
                        <div className="team-img-icon">
                            <div className="team-img rounded-circle">
                                <img src="img/team-4.jpg" className="img-fluid w-100 rounded-circle" alt=""/>
                            </div>
                            <div className="team-name text-center py-3">
                                <h4 className="">Christopher</h4>
                                <p className="m-0">Customers Support</p>
                            </div>
                            {/* <div className="team-icon d-flex justify-content-center pb-4">
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-linkedin-in"></i></a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="rounded team-item">
                    <div className="team-content">
                        <div className="team-img-icon">
                            <div className="team-img rounded-circle">
                                <img src="img/team-5.jpg" className="img-fluid w-100 rounded-circle" alt=""/>
                            </div>
                            <div className="team-name text-center py-3">
                                <h4 className="">Ambra</h4>
                                <p className="m-0">Administration</p>
                            </div>
                            {/* <div className="team-icon d-flex justify-content-center pb-4">
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-linkedin-in"></i></a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="rounded team-item">
                    <div className="team-content">
                        <div className="team-img-icon">
                            <div className="team-img rounded-circle">
                                <img src="img/team-6.jpg" className="img-fluid w-100 rounded-circle" alt=""/>
                            </div>
                            <div className="team-name text-center py-3">
                                <h4 className="">Jimmy</h4>
                                <p className="m-0">Research & Development</p>
                            </div>
                            {/* <div className="team-icon d-flex justify-content-center pb-4">
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-linkedin-in"></i></a>
                            </div> */}
                        </div>
                    </div>
                </div>
            {/* </div> */}
            </OwlCarousel>
        </div>
    </div>
    {/* Team End */}

    {/* Testimonial Start */}
    {/* <div id="testimonials" className="py-4"></div>
    <div className="container-fluid testimonial py-3 mb-5">
        <div className="container">
            <div className="text-center mx-auto pb-5" data-aos="fade-in" data-aos-duration="300" style={{maxWidth: "600px"}}>
                <h5 className="text-primary">Testimonials</h5>
                <h1>Our Client Saying!</h1>
            </div>
            <OwlCarousel {...optionsTestimonials} data-aos="fade-in" data-aos-duration="500">
            
                <div className="testimonial-item border p-4">
                    <div className="d-flex align-items-center">
                        <div className="">
                            <img src="img/testimonial-1.jpg" alt=""/>
                        </div>
                        <div className="ms-4">
                            <h4 className="text-secondary">Client Name</h4>
                            <p className="m-0 pb-3">Profession</p>
                            <div className="d-flex pe-5">
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                            </div>
                        </div>
                    </div>
                    <div className="border-top mt-4 pt-3">
                        <p className="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                    </div>
                </div>
                <div className="testimonial-item border p-4">
                    <div className=" d-flex align-items-center">
                        <div className="">
                            <img src="img/testimonial-2.jpg" alt=""/>
                        </div>
                        <div className="ms-4">
                            <h4 className="text-secondary">Client Name</h4>
                            <p className="m-0 pb-3">Profession</p>
                            <div className="d-flex pe-5">
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                            </div>
                        </div>
                    </div>
                    <div className="border-top mt-4 pt-3">
                        <p className="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                    </div>
                </div>
                <div className="testimonial-item border p-4">
                    <div className=" d-flex align-items-center">
                        <div className="">
                            <img src="img/testimonial-3.jpg" alt=""/>
                        </div>
                        <div className="ms-4">
                            <h4 className="text-secondary">Client Name</h4>
                            <p className="m-0 pb-3">Profession</p>
                            <div className="d-flex pe-5">
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                            </div>
                        </div>
                    </div>
                    <div className="border-top mt-4 pt-3">
                        <p className="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                    </div>
                </div>
                <div className="testimonial-item border p-4">
                    <div className=" d-flex align-items-center">
                        <div className="">
                            <img src="img/testimonial-4.jpg" alt=""/>
                        </div>
                        <div className="ms-4">
                            <h4 className="text-secondary">Client Name</h4>
                            <p className="m-0 pb-3">Profession</p>
                            <div className="d-flex pe-5">
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                                <i className="fas fa-star me-1 text-primary"></i>
                            </div>
                        </div>
                    </div>
                    <div className="border-top mt-4 pt-3">
                        <p className="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                    </div>
                </div>
            </OwlCarousel>
            
        </div>
    </div> */}

    {/* Testimonial End */}



    {/* Footer Start */}
     <div id="footer" className="container-fluid footer bg-dark" data-aos="fade-in" data-aos-duration="300">
        <div className="container pt-5 pb-4">
            <div className="row g-5">
                <div className="col-lg-6 col-md-6">
                    <a href={"#home"}>
                        <h1 className="text-white fw-bold d-block textSurf">Surf<span className="text-secondary textDefender">Defender</span> </h1>
                    </a>
                    <p className="mt-3 text-light">Say goodbye to the digital clutter and reclaim your online privacy with our ultimate content blocker designed to revolutionize your browsing experience!</p>
                    <p className="mt-1 text-light">Install Surf Defender now and embrace a secure, ad-free browsing experience!</p>
                    
                </div>
                <div className="col-lg-3 col-md-3">
                    <a href={"#footer"} className="h3 text-secondary">Short Links</a>
                    <div className="mt-4 d-flex flex-column short-link">
                        
                        <a href={"#about"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>About</a>
                        <a href={"#features"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Features</a>
                        <a href={"#getit"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Get It!</a>
                        <a href={"#team"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Our Team</a>
                        {/* <a href={"#testimonials"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Testimonials</a> */}
                        <Link to={"/faqs"}>
                            <span className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>FAQ</span>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3">
                    <a href={"#footer"} className="h3 text-secondary">Other Links</a>
                    <div className="mt-4 d-flex flex-column help-link">
                        <a href={"/terms"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Terms Of Use</a>
                        <a href={"/privacy"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Privacy Policy</a>
                        {/* <a href={"#footer"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Helps</a> */}
                        
                        {/* <a href={"#footer"} className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Contact</a> */}
                    </div>
                </div>
                {/* <div className="col-lg-3 col-md-6">
                    <a href={"#footer"} className="h3 text-secondary">Contact Us</a>
                    <div className="text-white mt-4 d-flex flex-column contact-link">
                        
                        <a href={"#footer"} className="py-3 text-light border-bottom border-primary small"><i className="fas fa-envelope text-secondary me-2"></i> universalsurfdefender@gmail.com</a>
                    </div>
                </div> */}
            </div>
            {/* <div className="row mt-5 footer-bottom">
                <div className="col-md-12 text-center">
                    <span className="text-muted small">Some images used on this website were created by Artificial Intelligence</span>
                </div>
            </div> */}
            <hr className="text-light"/>
            <div className="row footer-bottom">
                <div className="col-md-6 text-center text-md-start">
                    <span className="text-light"><a href={"#home"} className="text-secondary"><i className="fas fa-copyright text-secondary me-2"></i>Universal Software</a>, All right reserved.</span>
                </div>
                <div className="col-md-6 text-center text-md-end">                
                    <span className="text-muted">Distributed By <a href="https://universalsurfdefender.com">Universal Surf Defender</a></span>
                </div>
                
            </div>
        </div>
    </div>
    {/* Footer End */}


    {/* Back to Top */}
    {showtop ? 
        <a href={"#home"} className="btn btn-secondary btn-square rounded-circle back-to-top"><i className="fas fa-arrow-up text-white"></i></a>
    : <></>
    }

</section>

);

};

export default WebStart;