import React, { useContext } from "react";
import { UserContext } from "../../context";
import { Link, useNavigate } from "react-router-dom";
import RunningPlan from '../../pages/images/RunningPlan';


const PriceCard = ({ price, handleSubscription, userSubscriptions, products }) => {
  const [state] = useContext(UserContext);
  const navigate = useNavigate();

  const Intervals = [
    {
      priceInterval: 'none',
      priceIntervalCount: 0,
      corrispondence: "One Time"
    },
    {
      priceInterval: 'day',
      priceIntervalCount: 1,
      corrispondence: "Daily"
    },
    {
      priceInterval: 'week',
      priceIntervalCount: 1,
      corrispondence: "Weekly"
    },
    {
      priceInterval: 'month',
      priceIntervalCount: 1,
      corrispondence: "Monthly"
    },
    {
      priceInterval: 'year',
      priceIntervalCount: 1,
      corrispondence: "Yearly"
    },
    {
      priceInterval: 'month',
      priceIntervalCount: 6,
      corrispondence: "6 months"
    },
    {
      priceInterval: 'month',
      priceIntervalCount: 3,
      corrispondence: "3 months"
    },
  ]

  const dynamicDescription = () => {
    switch(price.nickname) {
      case "BASIC":
      case "DAILY":
      case "WEEKLY":
      case "MONTHLY":
      case "STARTER":
      case "STANDARD":
        return "You can cancel or change anytime";
      case "PREMIUM":
        return "Lifetime License";

    }
  };

  const productById = () => {
    const product = products.find((p) => { return p.id === price.product; });
    //console.log("PRODUCT:", product)
    return product ? product : "";
  }

  const intervalByPrice = () => {
    const interval = Intervals.find( i => { 
      return (price.recurring ? i.priceInterval === price.recurring.interval && i.priceIntervalCount === price.recurring.interval_count : null)
    });
    return interval ? interval.corrispondence : "One Time";
  }

  const buttonStyle = () => {
    switch(price.nickname) {
      case "DAILY": return "btn-secondary";
      case "WEEKLY": return "btn-secondary";
      case "MONTHLY": return "btn-secondary";
      case "STARTER": return "btn-secondary";
      case "BASIC": return "btn-warning";
      case "STANDARD": return "btn-success";
      case "PREMIUM": return "btn-danger";
    }
  };

  const headerStyle = () => {
    switch(price.nickname) {
      case "DAILY": return "bg-secondary";
      case "WEEKLY": return "bg-secondary";
      case "MONTHLY": return "bg-secondary";
      case "STARTER": return "bg-secondary";
      case "BASIC": return "bg-warning";
      case "STANDARD": return "bg-success";
      case "PREMIUM": return "bg-danger";
    }
  };

  const headerTitlesStyle = () => {
    switch(price.nickname) {
      case "DAILY": return "text-light";
      case "WEEKLY": return "text-light";
      case "MONTHLY": return "text-light";
      case "STARTER": return "text-light";
      case "BASIC": return "";
      case "STANDARD": return "text-light";
      case "PREMIUM": return "text-light";
    }
  }

  const borderStyle = () => {
    switch(price.nickname) {
      case "DAILY": return "border-secondary";
      case "WEEKLY": return "border-secondary";
      case "MONTHLY": return "border-secondary";
      case "STARTER": return "border-secondary";
      case "BASIC": return "border-warning";
      case "STANDARD": return "border-success";
      case "PREMIUM": return "border-danger";
    }
  };

  const buttonText = () => {
    //state ? console.log("STATE:",state) : console.log("State is null");
    return state && state.token ? "Buy this Plan" : "Log In";
  };

  // set product and interval for this plan
  const product = productById();
  const interval = intervalByPrice();
  

  return (
    <div className="col-lg">
      <div className={`card mb-4 rounded-0 rounded-bottom ${borderStyle()}`}>
        <div className={`row g-0 rounded-0  ${headerStyle()}`}>

          <div className="col-lg-1 mt-1">
            
            {userSubscriptions && userSubscriptions.includes(price.id) ? 
              <span className="bs-icon-md bs-icon-rounded d-flex justify-content-center me-2 bs-icon">
                  {price.nickname === "STANDARD" || price.nickname === "STARTER" ? 
                    RunningPlan("3em", "1em", "#FFFFFF", "#6c757d") : 
                    RunningPlan("3em", "1em", "#000000", "#6c757d")}
              </span> 
              : <></>}
              
          </div>
          
          <div className="col-lg">
            <div className={`card-header py-3 ${headerStyle()}`}>
              <h4 className={`my-0 fw-bold ${headerTitlesStyle()}`}>{price.nickname}</h4>
              <h5 className={`my-0 fw-normal ${headerTitlesStyle()}`}>{product.name}</h5>
            </div>
          </div>
          <div className="col-lg-1 mt-1">
            
            {userSubscriptions && userSubscriptions.includes(price.id) ? 
              <span className="bs-icon-md bs-icon-rounded d-flex justify-content-center me-2 bs-icon">
                  {price.nickname === "STANDARD" || price.nickname === "STARTER" ? 
                    RunningPlan("3em", "1em", "#FFFFFF", "#6c757d") : 
                    RunningPlan("3em", "1em", "#000000", "#6c757d")}
              </span> 
              : <></>}
              
          </div>
        </div>

        <div className="card-body">
          <h1 className="card-title pricing-card-title">
            {(price.unit_amount / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}{" "}            
          </h1>
          <h5 className="text-muted fw-light">{interval}</h5>
          <ul className="list-unstyled mt-3 mb-4">
            <li className="fw-bold fs-5">{dynamicDescription()}</li>
            <li>Free updates</li>
            <li>Email support</li>
            <li>Personal account center access</li>
          </ul>

          {/* <pre>{JSON.stringify(price, null, 4)}</pre> */}

          
          <button
            onClick={(e) => handleSubscription(e, price)}
            className={`w-100 btn btn-lg ${buttonStyle()}`}
          >
            {userSubscriptions && userSubscriptions.includes(price.id)
              ? "Plan Status"
              : buttonText()}
          </button>
          {! state || !state.token ?
          <div className="row">
            <p className="card-text fs-6">Don't have an account yet? <Link className="card-text" to="/register">Sign Up</Link> for free</p> 
            {/* <p className="card-text fs-6">Don't have an account yet?<a onClick={navigate("/register")}>Sign Up</a> for free</p> */}
          </div>
           : <></>}
          
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
