import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context";
import USDsvg from '../pages/images/Usdsvg';
import { HashLink } from 'react-router-hash-link';
import { isAuth } from "../utils/functions";

const Nav = () => {
  const [state, setState] = useContext(UserContext);
  const navigate = useNavigate();

  const [color, setColor] = useState(false);
  const [click, setClick] = useState(false);

    
  useEffect(() => {

    var last_scroll_top = 0;  

    const changeColor = () => {
      
      try {
      let el_autohide = document.querySelector('.autohide');
           
      let scroll_top = window.scrollY;
      if(scroll_top <= last_scroll_top) {
          el_autohide.classList.remove('scrolled-down');
          el_autohide.classList.add('scrolled-up');
      }
      else {
          el_autohide.classList.remove('scrolled-up');
          el_autohide.classList.add('scrolled-down');
      }
      last_scroll_top = scroll_top;
    } catch(ex) {}
    
      if (window.scrollY >= 300) {
        setColor(true)
       } else {
        setColor(false)
      }
      
    }

    window.addEventListener('scroll', changeColor);
  },[])
  

  function logout() {
    setState({ user: {}, token: "" });
    localStorage.removeItem("auth");
    //navigate.push("/login");
    
    navigate("/");
  };

  //console.log("STATE => ", state);

  return (
    <section>
    {/* Navbar Start */}
    {/* <div ref={mainRef} className="container-fluid bg-white sticky-top"> */}
    {/* <div className={color ? 'navbar navbarBV' : 'navbar'}> */}
        <div className="container">
            <nav className={color ? "autohide navbar navbarBV navbar-expand-lg navbar-light py-0 py-lg-0" : "autohide navbar navbar-expand-lg navbar-light py-0 py-lg-0"}>
              
                {/* <a href={"#home"} className="navbar-brand fs-4"> */}
                <HashLink to="#home" className="navbar-brand fs-4">
                    {/* <img className="img-fluid" src="img/logo.png" alt="Logo"/> */}

                      <div className="container-fluid d-flex">
                        <span className="bs-icon-md bs-icon-rounded mt-1 me-2 bs-icon">
                          {USDsvg("3em", "3em")}
                        </span>
                        
                        <h1 className="text-white fw-bold d-block mt-3 textSurf">Surf<span className="text-secondary textDefender">Defender</span> </h1>
                        {/* <span className="navbar-text textSurf me-2 mt-2">Surf</span>               
                        <span className="navbar-text textDefender mt-2 text-white">Defender</span> */}
                        
                       
                      </div>

                </HashLink>    
                {/* </a> */}
                
                <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end mt-3" id="navbarCollapse">
                    
                  <ul className={click ? "navbar-nav ms-3 mt-2 active" : "navbar-nav ms-3 mt-2"}>
                  
                    <li className="nav-item">
                      {/* <a href={"#home"} className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Home</a> */}
                      <HashLink to="#home"><span className="nav-link pt-1" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Home</span></HashLink>
                    </li>
                    <li className="nav-item">
                      <HashLink to="#about"><span className="nav-link pt-1" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">About</span></HashLink>
                      {/* <a href={"#about"} className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">About</a> */}
                    </li>
                    <li className="nav-item">
                      <HashLink to="#features"><span className="nav-link pt-1" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Features</span></HashLink>
                      {/* <a href={"#features"} className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Features</a> */}
                    </li>
                    <li className="nav-item">
                      <HashLink to="#team"><span className="nav-link pt-1" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Team</span></HashLink>
                      {/* <a href={"#team"} className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Team</a> */}
                    </li>
                    <li className="nav-item"> 
                      <HashLink to="#getit"><span className="nav-link pt-1" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">GetIt!</span></HashLink>                       
                      {/* <a href={"#getit"} className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Get It!</a> */}
                    </li>
                    <li className="nav-item">
                      <Link to={"/faqs"}>
                              <span className="nav-link pt-1" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">FAQ</span>
                      </Link>
                    </li>  
                    {/* <li className="nav-item">
                      <Link to={"/reportissue"}>
                              <span className="nav-link me-3 pt-1" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Report Issue</span>
                      </Link>
                    </li> */}
                        
                    {state && state.token ? (
                      <li className="nav-item dropdown"> 
                      {/* it was <div className="nav-item dropdown"> */}
                        
                        <a href="#" className="nav-link dropdown-toggle mt-0 pt-1" id="emaildropdown" role="button" aria-expanded="false" data-bs-toggle="dropdown">
                          {state.user.email}
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="emaildropdown">
                          <li className="dropdown-item" >
                            <Link className="nav-link p-1" to="/account">
                              Account
                            </Link>
                          </li>
                          <li className="dropdown-item" >
                            <Link className="nav-link p-1" to="/reportissue">
                              Report an Issue
                            </Link>
                          </li>
                          <li className="dropdown-item">
                            <a href="#" onClick={logout} className="nav-link p-1">
                              Logout
                            </a>
                          </li>
                        </ul>
                      </li> 
                      /* it was </div> */
                    ) : (
                      <li className="nav-item">
                        <Link to={"/plans"}>
                                <span className="nav-link pt-1" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Subscribe</span>
                        </Link>
                      </li>
                      // <Fragment>
                        
                      //     <Link className="nav-item nav-link me-3 pt-1" to="/plans">
                      //       Subscribe
                      //     </Link>
              
                      // </Fragment>
                    )}
                  </ul>
                </div>
            </nav>
        </div>
    {/* </div> */}
    {/* Navbar End */}

    
    </section>
  );
};

export default Nav;
