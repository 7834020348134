import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import AuthRoute from "./components/routes/AuthRoute";
import Nav from "./components/Nav";
import NavCart from "./components/NavCart";
import NavFaqs from "./components/NavFaqs";
import Plans from "./pages/Plans";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import StripeSuccess from "./pages/stripe-success";
import StripeCancel from "./pages/stripe-cancel";
import StripeSubscribed from "./pages/stripe-subscribed";
import Account from "./pages/Account";
import Subscribed from "./pages/Subscribed";
import Basic from "./pages/plans/Basic";
import Standard from "./pages/plans/Standard";
import Premium from "./pages/plans/Premium";
import WebStart from "./pages/WebStart";
import Faqs from "./pages/Faqs";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import InstallIOS from "./pages/Install-iOS";
import ReportIssue from "./pages/ReportIssue";


function App() {
  return (
    <Router>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 3000,
        }}
      />
      <Routes>
        <Route exact path="/" element={<><Nav /><WebStart /></>} />
        <Route exact path="/faqs" element={<><NavFaqs /><Faqs /></>} />
        <Route exact path="/privacy" element={<><NavFaqs /><Privacy /></>} />
        <Route exact path="/terms" element={<><NavFaqs /><Terms /></>} />
        <Route exact path="/enableusd" element={<><NavFaqs /><InstallIOS /></>} />
        <Route exact path="/reportissue" element={<><NavFaqs /><ReportIssue /></>} />

        <Route exact path="/plans" element={<><NavCart /><Plans /></>} />
        <Route exact path="/register" element={<><NavCart /><Register /></>} />
        <Route exact path="/login" element={<><NavCart /><Login /></>} />
        <Route exact path="/reset" element={<><NavCart /><Reset /></>} />
        
        <Route exact path="/stripesubscribed" element={<><NavCart /><AuthRoute Component={StripeSubscribed} /></>} />
        <Route exact path="/stripesuccess" element={<><NavCart /><AuthRoute Component={StripeSuccess} /></>} />
        <Route exact path="/stripecancel" element={<><NavCart /><AuthRoute Component={StripeCancel} /></>} />
        <Route exact path="/account" element={<><NavCart /><AuthRoute Component={Account} /></>} />
        <Route exact path="/subscribed" element={<><NavCart /><AuthRoute Component={Subscribed} /></>} />
        <Route exact path="/basic" element={<><NavCart /><AuthRoute Component={Basic} /></>} />
        <Route exact path="/standard" element={<><NavCart /><AuthRoute Component={Standard} /></>} />
        <Route exact path="/premium" element={<><NavCart /><AuthRoute Component={Premium} /></>} />
        {/* <Route exact path="/reportissue" element={<><NavCart /><AuthRoute Component={ReportIssue} /></>} /> */}
      </Routes>
    </Router>
  );
}

export default App;
