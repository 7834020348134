import React from "react"; 

const USDsvg = (w,h) => {
    return (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={w} height={h}
        fill="#0000FF" 
        stroke="#0AF" strokeWidth="1"
        viewBox="0 0 24 24">
        <g
            id="g1900">
            <path
            d="M22 23V21C20.59 21.05 19.22 20.9 18 20C16.24 21.25 13.77 21.25 12 20C10.23 21.25 7.76 21.25 6 20C4.78 20.9 3.41 21.05 2 21V23C3.37 23.05 4.78 22.94 6 22.25C7.84 23.25 10.15 23.25 12 22.25C13.85 23.25 16.16 23.25 18 22.25C19.22 22.94 20.63 23.05 22 23M20.5 11.97V9.95C18.74 9.96 17 9 16.18 7.41L15.29 5.86C15 5.4 14.55 5.11 14.07 4.97L8.5 3.94L5 6.38L6.13 8L8.95 6.1L10.97 6.43L8.81 7.88C8.21 8.29 7.88 9 8 9.71L8.53 12.81C7.12 12.26 3.21 11 3 13.23C3.36 15.67 6.07 17.32 8.06 18.82C9.5 19.29 11.06 18.58 12 17.46C13.32 19.16 15.89 19.46 17.5 18C17.03 17.58 16.5 17.15 16 16.8V12.95L12.19 9.95L14.44 8.44C15.62 10.59 18 12 20.5 11.97M14 14V15.47C14 15.43 11.66 14.16 11.81 14.24L10.73 13.7L10.31 11.08L14 14M19 3.5C19 4.61 18.11 5.5 17 5.5S15 4.61 15 3.5 15.9 1.5 17 1.5 19 2.4 19 3.5Z"
            id="path10"
            fill="#0000ff" fillOpacity={1} />
            <g
                id="g490"
                transform="matrix(0.42261323,0,0,0.42261323,0.31280547,2.2548062)">
                <path
                d="m 10.131388,13.459986 8.257788,1.764477 c -0.700201,5.890806 -4.206964,9.687385 -8.216406,9.247931 L 10.131388,13.459986 4.3473809,12.224092 4.3431708,6.2980299 10.090982,2.7074026 m -0.01,-2.65400717 L 3,4.9621711 v 5.9745809 c 0,5.56023 2.708084,12.19915 7.182489,16.121958 5.806051,1.467266 11.327392,-3.925296 11.241562,-12.77007 l -0.0919,-9.4701064 z"
                fill="#000000" fillOpacity={1} stroke="#00aaff" strokeOpacity={1} strokeWidth={1}
                id="path2" /><path
                id="path291"
                fill="#fff50f" strokeWidth={0.38}
                transform="matrix(1,0,0,1.0524476,2.265233,1.7946211)"
                d="M 16.17819,12.505542 8.0472576,10.902609 7.9786998,0.88447277 16.112363,4.8136364 Z" />
            </g>
        </g>
    </svg>
    )
}
export default USDsvg;