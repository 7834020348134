import React, { useState, useEffect, useContext } from "react";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { UserContext } from "../context";
import moment from "moment";
import {useNavigate, Navigate} from "react-router-dom";
import { isAuth } from "../utils/functions";

const Account = () => {
  const navigate = useNavigate();
  
  const [state, setState] = useContext(UserContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const [ready, setReady] = useState(false);
  

  useEffect(() => {
    const getSubscriptions = async () => {
      const { data } = await axios.get("/subscriptions");
//      console.log("subs => ", data);
      //setSubscriptions(data.data);
      setSubscriptions(data);
      
      setReady(true);
    };
    
    isAuth().then(res => {
      if (res==true && state && state.token) {
      
        getSubscriptions();
      } else {
        setState({ user: {}, token: "" });
        navigate("/login");
      }

    })
    
    // if (state && state.token) {
    //   getSubscriptions();
    // } else {
    //   navigate("/login");
    // }
    
  }, [state && state.token && ready]);

  useEffect(() => {
    const getInvoices = async () => {
      const { data } = await axios.get("/invoices");
      //console.log("invoices => ", data);
      setInvoices(data.data);
    };

    isAuth().then(res => {
      if (res==true && state && state.token) {
        getInvoices();
      } else {
        setState({ user: {}, token: "" });
        navigate("/login");
      }
    });
  }, [state && state.token]);

  const manageSubscriptions = async () => {
    const { data } = await axios.get("/customer-portal");
    window.open(data, "_self");
  };

  const manageRefunds = async (event) => {
    const subscription = JSON.parse(event.target.getAttribute('data-arg'));
    //console.log("SUB;", subscription)
    const { data } = await axios.post("/ask-refund", {
      subscription: subscription.id,
    });

    //console.log("SUBSCRIPTIONS:", data)
  };

  return !ready ? null : (


    <div className="container contentHead">
      {state && state.token ?
      <section>
        <div className="row d-flex justify-content-center pb-4">
          <UserOutlined className="display-4" />
          <h1 className="text-center">{state.user.name}</h1>
          <h3 className="text-center">{state.user.email}</h3>
          
          {/* <pre>{JSON.stringify(subscriptions, null, 4)}</pre> */}
        </div>

        <div className="row d-flex">
          <div className="col-md-6 col-xl-6">
            <p className="lead text-center">Free Trial status</p>
            <hr />
            <div>                                                                
                <h4 className="fw-bold">FREE TRIAL</h4>
                <p className="">Status:<span className="ms-1" id="trialstatus">{state.user.trial_end - Date.now() >= 0 ? "ACTIVE" : "EXPIRED"}</span></p>
                <p className="">Trial start:<span className="ms-1" id="trialstart">{moment(state.user.trial_start)
                          .format("dddd, MMMM Do YYYY h:mm:ss a")
                          .toString()}</span></p>
                <p className="">Trial duration:<span className="ms-1 me-1" id="trialduration">{state.user.trial_duration}</span>days</p>
                <p className="">Trial end:<span className="ms-1" id="trialend">{moment(state.user.trial_end)
                          .format("dddd, MMMM Do YYYY h:mm:ss a")
                          .toString()}</span></p>
            </div>
          </div>
          <div className="col-md-6 col-xl-6">
            <p className="lead text-center">Subscription status</p>
            <hr />
            { subscriptions.length>0 ?
            <section>
              <div className="row">
                { //subscriptions.length>0 &&
                subscriptions.map((sub) => (
                  <div key={sub.id}>
                    {/* {sub.status === 'active' ? */}
                    <section>
                      
                      <h4 className="fw-bold">{sub.plan.nickname} PLAN</h4>
                      <h5>
                        {(sub.plan.amount / 100).toLocaleString("en-US", {
                          style: "currency",
                          currency: sub.plan.currency,
                        }) + "/" + (sub.plan.interval_count > 1 ? sub.plan.interval_count.toString() + " " + sub.plan.interval + "s" : sub.plan.interval)}
                      </h5>

                      {
                        sub.status === "active" ?
                          <section>
                            <span>Status: </span><span style={{color:'#00FF00'}}>ACTIVE</span>
                          </section>
                        : 
                          <section>
                            <span>Status: </span><span style={{color:'#FF0000'}}>PAST DUE</span><br />
                            <span style={{color:'#FF0000'}}>Warning - Last subscription renewal failed!</span><br />
                            <span>You are currently using Surf Defender in a limited Grace Period.</span><br />
                            <span>Please manage your subscription to correct the issue.</span>
                          </section>
                      }
                      <br />
                      { sub.cancel_at ?
                        
                        <div>
                          <hr />
                          <p>You requested cancellation at{" "}
                            {moment(sub.canceled_at * 1000)
                            .format("dddd, MMMM Do YYYY h:mm:ss a")
                            .toString()}
                          </p>
                          <p>This subscription will be no more renewed after{" "}
                          {moment(sub.cancel_at * 1000)
                          .format("dddd, MMMM Do YYYY h:mm:ss a")
                          .toString()}
                          </p>
                          <hr />
                        </div>
                        
                        : <></>
                      }
                      {sub.default_payment_method && sub.default_payment_method.card ?
                        <p>
                        Card last 4 digit: {sub.default_payment_method.card.last4}
                      </p>
                      : <></>
                      }
                      
                      <p>
                        Current period end:{" "}
                        {moment(sub.current_period_end * 1000)
                          .format("dddd, MMMM Do YYYY h:mm:ss a")
                          .toString()}
                      </p>
                      
                      
                      <button
                        onClick={manageSubscriptions}
                        className="btn btn-danger"
                      >
                        Manage Subscription
                      </button>

                      { /*invoices && !sub.cancel_at ?
                        <button
                        onClick={manageRefunds}
                        data-arg={JSON.stringify(sub)}
                        className="btn btn-danger ms-3"
                      >
                        Ask Refund
                        </button>
                        : <></> */}
                    </section>
                    {/* : <></> } */}
                  </div>
                ))}
              </div>
            </section>
            : 
            <section>
              <p className="lead text-center">No active subscription</p>
            </section>        
             }
          </div>
      </div>
      </section>
      : <Navigate to="/plans" /> }
    </div>

  );
};

export default Account;
